* {
    font-family: Pyidaungsu 'Pyidaungsu MM' !important;
}

.tabButton{
    border: solid rgba(255,200,0,0.3) 1px;
    background-image: linear-gradient(70deg, rgba(255,200,0,0.0), rgba(255,200,0,0.5));
    color: rgba(255,200,0,1);
}

.tabButton :active{
    color:rgba(255,200,0,1);
}

.textField input{
    background-image: linear-gradient(70deg, rgba(200,150,0,0.5), rgba(255,200,0,0.5));
}

.button{
    background-image: linear-gradient(70deg, rgba(200,150,0,1), rgba(255,200,0,1))
}

.button.disabled{
    background-image: linear-gradient(70deg, rgba(200,200,200,1), rgba(220,220,220,1));
    color: black;
}


.tab{
   color : rgb(255,200,0);
}

.tab:active{
    border-bottom : solid 1px rgb(255,200,0);

}
.App {
  text-align: center;
}

.flip-card-front {
  transition: 2.5s;
}

.flip-card-back {
  transition: 2.5s;
}

.flip-card-back.flipped { 
  transform-origin: 0%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.flip-card-front.flipped{ 
  transform-origin: 0%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

@font-face {
  font-family: "Card";
  src: url("CardCharactersNarrowFigures.ttf") format("truetype");
  }